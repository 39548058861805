import { useLazyQuery } from '@apollo/client';
import React, { useContext, useEffect, useLayoutEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  GetGradeBookPageDataDocument,
  SubmissionStatus,
  TeacherEvaluationPageSubmissionFragmentDoc,
} from '../../services/graphql/types/graphql';
import { AuthingContext } from '../App/authing.context';
import { useStoreContext } from '../../factorys/useStoreContext';
import { IdentityContext } from '../App/identity.context';
import { useSearchParam } from 'react-use';
import MuYiTongXueLogo from '../../assets/muyitongxue-logo.png';
import { Tooltip } from 'react-tooltip';
import { Loader } from '../../components/Loader';
import { ossImage } from '../../utils/ossImage';
import qs from 'query-string';
import { PortalModal } from '../../components/PortalModal';
import { Transcript } from '../Transcript';
import { CloseCircleOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';
import cx from 'classnames';
import IdentitySelector from '../../components/IdentitySelector';
import { useQueryState } from '../../hooks/useQueryState';

export const GradeBookPage = () => {
  const navigate = useNavigate();
  const [classId, setClassId] = useQueryState('classId', '');

  const [trascriptSubmissionId, setTrascriptSubmissionId] = React.useState('');
  const { user, logout } = useContext(AuthingContext);

  const { currentIdentity, currentClass, currentCourseId } = useStoreContext(IdentityContext);
  const role = currentIdentity?.identity;
  // useEffect(() => console.info('role', role), [role]);

  useEffect(() => {
    setClassId(currentClass?.classId ?? '');
  }, [currentClass]);

  useEffect(() => {
    if (role === 'student') {
      navigate('/');
    }
  }, [currentIdentity, classId]);

  useEffect(() => {
    if (role === 'student') {
      navigate('/');
    }
  }, [role]);

  const [getData, { loading, error, data }] = useLazyQuery(GetGradeBookPageDataDocument);
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  useEffect(() => {
    // console.info('start getData');
    if (classId && role) {
      getData({
        variables: {
          classId,
          courseId: currentCourseId || null,
        },
      }).catch((err) => console.info(err));
    }
    // console.info('done getData');
  }, [classId, currentCourseId, getData, role]);

  const handleClickAssignment = (assignmentId: string) => () => {
    const query = qs.stringify({
      assignmentId,
      classId: data?.classCourse.classes[0].classId,
      courseId: data?.classCourse.courseId,
    });
    navigate(`/eval?${query}`);
  };

  if (loading) {
    return <Loader fullscreen></Loader>;
  }

  return (
    <div className="bg-[#D8D4CF] overflow-hidden h-full w-full flex flex-col">
      <header className="h-[180px] flex items-center">
        <img className="w-[120px] h-[120px] ml-14" src={MuYiTongXueLogo} alt="" />
        <div className="ml-auto mr-11">
          <div className="text-white h-[70px] flex bg-[#797979] rounded-[35px] items-center px-[13px] py-[10px]">
            <img className="rounded-full w-[50px] h-[50px]" src={user?.photo ?? ''} alt="" />
            <div className="flex flex-col ml-[1.389vw] font-semibold">
              <div className="text-[22px]">{user?.nickname ?? user?.name}</div>
              <div className="text-[12px]">班主任老师</div>
            </div>
            <div
              onClick={logout}
              className="text-[#797979] ml-[1.389vw] rounded-full w-[50px] h-[50px] bg-white flex items-center justify-center"
            >
              退出
            </div>
          </div>
        </div>
        <div className="absolute top-[4.02vw] left-1/2 -translate-x-1/2 z-50 text-[#797979]">
          <IdentitySelector enableCourse enableClass></IdentitySelector>
        </div>
      </header>
      <main className="overflow-auto translate-x-0 h-full flex-1">
        {data?.classCourse.classes.map((clas, iClass) => {
          return (
            <div key={iClass}>
              <div className="text-white bg-[#797979] h-12 sticky top-0 left-0 z-20 flex pl-11 space-x-4 [&>*]:flex-shrink-0">
                <div className="flex items-center text-[20px]">
                  <div className="vertical-lr text-[10px]">课程</div>
                  <div className="ml-3">
                    <span className="font-bold">{data?.classCourse.name.split('_')[0]}</span>
                    <span className="ml-3">{data?.classCourse.name.split('_')[1]}</span>
                  </div>
                </div>
                <div className="flex items-center text-[16px]">
                  <div className="vertical-lr text-[10px]">班级</div>
                  <div className="ml-3">
                    <span>{clas?.name}</span>
                  </div>
                </div>
              </div>
              <div className="w-full h-full">
                <table className="grade-book">
                  <colgroup>
                    <col key="col-0" className="w-24" />
                    <col key="col-1" className="w-44" />
                    {data?.classCourse.lessons.map((lesson, iLesson) => {
                      return (
                        <React.Fragment key={`col-frag-${lesson.lessonId}`}>
                          {lesson.assignments.map((assignment, iAssignment) => {
                            return (
                              <col
                                key={`col-${lesson.lessonId}-${assignment.assignmentId}`}
                                className="w-12"
                              ></col>
                            );
                          })}
                        </React.Fragment>
                      );
                    })}
                    <col key="col-spacing" className="w-auto" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th className="!border-0"></th>
                      <th className="!border-0"></th>
                      {data?.classCourse.lessons.map((lesson) => {
                        return (
                          <th colSpan={lesson.assignments.length} key={lesson.lessonId}>
                            {lesson.name}
                          </th>
                        );
                      })}
                      <th></th>
                    </tr>
                    <tr>
                      <th className="!border-0"></th>
                      <th className="!border-0"></th>
                      {data?.classCourse.lessons.map((lesson, iLesson) => {
                        return (
                          <React.Fragment key={`th-lesson-frag-${lesson.lessonId}`}>
                            {lesson.assignments.map((assignment, iAssignment) => {
                              return (
                                <th
                                  className="text-xs"
                                  key={assignment.assignmentId}
                                  onClick={handleClickAssignment(assignment.assignmentId)}
                                >
                                  <Tooltip
                                    className="z-30"
                                    id={`assignment-tooltip--${assignment.assignmentId}`}
                                  />
                                  <a
                                    data-tooltip-id={`assignment-tooltip--${assignment.assignmentId}`}
                                    data-tooltip-content={assignment.name}
                                    data-tooltip-place="top"
                                  >
                                    {iAssignment + 1}
                                  </a>
                                </th>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {clas.classUsers.map((u, iUser) => {
                      return (
                        <tr key={iUser}>
                          <td>{u.studentNumber}</td>
                          <td className="text-[22px] text-white pl-7 py-2 w-44">
                            <div className="flex items-center w-full h-full [&>*]:flex-shrink-0 overflow-hidden">
                              <div className="bg-white w-9 h-9 rounded-full" />
                              <div className="ml-3">{u.user.name}</div>
                            </div>
                          </td>
                          {data?.classCourse.lessons
                            .map((lesson) => lesson.assignments)
                            .flat()
                            .map((assignment) => {
                              const submission = assignment?.submissions.find(
                                (s) => s.authors[0].userId === u.userId,
                              );

                              return (
                                <td
                                  className={cx(
                                    'text-xs',
                                    submission?.submissionId === trascriptSubmissionId &&
                                      '!bg-[#8b8b8b]',
                                  )}
                                  key={assignment.assignmentId}
                                >
                                  <a
                                    onClick={() =>
                                      setTrascriptSubmissionId(submission?.submissionId ?? '')
                                    }
                                    className={cx(
                                      'font-black',
                                      submission?.submissionStatus === SubmissionStatus.Completed &&
                                        'text-green-500',
                                      submission?.submissionStatus ===
                                        SubmissionStatus.TaApproved && 'text-yellow-500',
                                      submission?.submissionStatus === SubmissionStatus.Submitted &&
                                        'text-red-500',
                                      submission?.submissionStatus === SubmissionStatus.Retracted &&
                                        'text-blue-500',
                                      submission?.submissionStatus ===
                                        SubmissionStatus.CompletedButNoGrade && 'text-purple-500',
                                    )}
                                  >
                                    {submission &&
                                      submission.submissionStatus !==
                                        SubmissionStatus.Unsubmitted &&
                                      '◯'}
                                  </a>
                                </td>
                              );
                            })}
                          <td></td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
      </main>
      {trascriptSubmissionId && (
        <aside className="absolute right-0 top-0 bottom-0 border-l border-2 border-black">
          <Transcript submissionId={trascriptSubmissionId}></Transcript>
          <div className="absolute flex justify-center items-center w-6 h-6 z-[100] top-[5px] right-[5px] leading-6 text-2xl text-[#7d7d7d] transition-opacity">
            <CloseCircleOutlined onClick={() => setTrascriptSubmissionId('')} />
          </div>
        </aside>
      )}
    </div>
  );
};
