import React, { useEffect } from 'react';
import { isMobileOnly } from 'react-device-detect';
import Div100vh from 'react-div-100vh';
import { useOrientation, useUpdate } from 'react-use';

function isWechat() {
  return /MicroMessenger/i.test(window.navigator.userAgent);
}

export const PlatformDetector: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const orientation = useOrientation();
  const update = useUpdate();

  useEffect(() => {
    update();
  }, [orientation, update]);

  if (isMobileOnly || isWechat()) {
    return (
      <Div100vh className="flex justify-center items-center text-2xl pb-36 text-[#797979]">
        请使用PC/Mac浏览器打开
      </Div100vh>
    );
  }

  return children;
};
