import React from 'react';

// @ts-ignore
import toSorted from 'array.prototype.tosorted';
// @ts-ignore
toSorted.shim();

import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';

import { App } from './containers/App';

import './main.scss';
import 'rc-select/assets/index.less';

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

Sentry.init({
  dsn: 'https://7a14ee3aa6299ee9fc6aae8c2ae4cee1@o4506100073627648.ingest.sentry.io/4506100075528192',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['localhost', /^https:\/\/coca\.xinyixue\.art/],
    }),
    new Sentry.Replay(),
  ],
  sampleRate: import.meta.env.PROD ? 1.0 : 0, // Capture 100% of transactions by default
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);
