import { CloseCircleOutlined } from '@ant-design/icons';
import { useApolloClient, useMutation } from '@apollo/client';
import { ossImage } from '../../../../utils/ossImage';
import { ReactComponent as Spinner } from '@Assets/spinner.svg';
import cx from 'classnames';
import {
  DeleteAssignmentMediaSimpleDocument,
  HomePageArtworkMediaFragment,
  HomePageArtworkMediaFragmentDoc,
  TeacherEvaluationPageSubmissionFragmentDoc,
} from '../../../../services/graphql/types/graphql';
import { FragmentType, getFragmentData } from '../../../../services/graphql/types';

export const ArtworkItem = ({
  assignmentId,
  data,
  deleteAble = true,
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {
  assignmentId: string;
  data: HomePageArtworkMediaFragment;
  deleteAble: boolean;
}): JSX.Element => {
  const apolloClient = useApolloClient();
  const [deleteArtworkMutation, { loading }] = useMutation(DeleteAssignmentMediaSimpleDocument, {
    // update(cache, { data }) {
    //   const media = getFragmentData(TeacherEvaluationPageSubmissionFragmentDoc, data?.deleteAssignmentMediaSimple);
    //   cache.modify({
    //     id: cache.identify(media),
    //     fields: {
    //       url() {
    //         return null;
    //       },
    //     },
    //   });
    // },
  });

  const media = data;

  const onClickDeleteButton = () => {
    deleteArtworkMutation({ variables: { assignmentId, mediaId: media.mediaId } }).then(() => {
      console.info(`Delete media ${media.mediaId}`);
    }).catch(
      console.error,
    );
  };

  return (
    <div className="w-full h-full bg-white relative">
      {media?.url && (
        <>
          <img
            className="w-full h-full object-cover"
            src={ossImage(media?.url, { resize: { w: 240, h: 240, m: 'fill' } })}
            alt=""
            draggable={false}
            {...props}
          />
          <div className="absolute flex justify-center items-center w-6 h-6 z-[100] top-[5px] right-[5px] leading-6 text-2xl text-[#7d7d7d] transition-opacity">
            {deleteAble &&
              (loading ? (
                <Spinner></Spinner>
              ) : (
                <CloseCircleOutlined onClick={onClickDeleteButton} />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
