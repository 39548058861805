import React, { useContext, useEffect, useMemo, useRef } from 'react';
import TranscriptHeaderImage from './assets/transcript-header.png';
import MuYiTongXueLogoImage from './assets/muyitongxue-logo.png';
import { useQuery } from '@apollo/client';
import { GetTranscriptDataDocument, SubmissionStatus } from '../../services/graphql/types/graphql';
import { Loader } from '../../components/Loader';
import { AuthingContext } from '../App/authing.context';
import { IdentityContext } from '../App/identity.context';
import { useStoreContext } from '../../factorys/useStoreContext';
import { keyBy, sum } from 'lodash';
import { ReactComponent as RedStarSVG } from './assets/red-star.svg';
import YellowStarPNG from './assets/yellow-star.png';
import cx from 'classnames';
import * as Sentry from '@sentry/react';

import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';

export const Transcript: React.FC<{
  submissionId: string;
  minimal?: boolean;
  info?: boolean;
}> = ({ submissionId, minimal = false, info = false }) => {
  const { user } = useContext(AuthingContext);
  const { currentIdentity } = useStoreContext(IdentityContext);
  const { data, loading, error } = useQuery(GetTranscriptDataDocument, {
    variables: { submissionId },
  });
  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  const taCredits = useMemo(() => {
    const taCredits = data?.submission.credits.filter(
      ({ rubric }) => rubric.graderRoleType === 'TA',
    );
    return taCredits;
  }, [data]);

  const teacherCredits = useMemo(() => {
    const teacherCredits = data?.submission.credits.filter(
      ({ rubric }) => rubric.graderRoleType === 'Teacher',
    );
    return teacherCredits;
  }, [data]);

  const totalGrade = useMemo(() => {
    if (!data) {
      return 0;
    }

    const assignmentRubrics = keyBy(
      data.submission.assignment.assignmentRubrics,
      'rubric.rubricId',
    );

    const creditGrades = data.submission.credits.map(({ rubric, rubricLevel }) => {
      const rubricId = rubric.rubricId;
      const rubricLevelId = rubricLevel.rubricLevelId;
      if (!rubricId || !rubricLevelId) {
        return 0;
      }
      const rubricLevelGrade = assignmentRubrics[rubricId].rubric?.rubricLevels.find(
        (rl) => rl.rubricLevelId === rubricLevelId,
      )?.grade;

      return assignmentRubrics[rubricId].weight * (rubricLevelGrade ?? 0);
    });

    return sum(creditGrades) + (data.submission.gradeOffset ?? 0);
  }, [data]);

  const artworkMediaUrls = useMemo(() => {
    return (
      data?.submission.artworks
        .map((artwork) => {
          return artwork.medias.map((media) => {
            return media.url;
          });
        })
        .flat() ?? []
    );
  }, [data]);

  const artworkMediaShapes = useMemo(() => {
    switch (artworkMediaUrls.length) {
      case 2:
        return [1, 2];
      default:
        return [
          Math.ceil(Math.sqrt(artworkMediaUrls.length)),
          Math.ceil(Math.sqrt(artworkMediaUrls.length)),
        ];
    }
  }, [artworkMediaUrls]);

  const refContainer = useRef<HTMLDivElement>(null);
  const saveAsImage = async () => {
    if (!refContainer.current) {
      return;
    }
    html2canvas(refContainer.current, { useCORS: true, scale: 1 }).then(
      function (canvas) {
        saveAs(canvas.toDataURL(), 'transcript.png');
      },
    );
  };

  const gradeToStar = (grade: number) => {
    let numStar;
    switch (grade) {
      case 90:
        numStar = 5;
        break;
      case 80:
        numStar = 4;
        break;
      case 70:
        numStar = 3;
        break;
      case 60:
        numStar = 2;
        break;
      default:
        numStar = 0;
        break;
    }

    return numStar;
  };

  if (loading || !data || !user) {
    return <Loader></Loader>;
  }

  if (info) {
    if (data.submission.submissionStatus === SubmissionStatus.CompletedButNoGrade) {
      return <div className="text-[#F0403C] text-3xl mt-auto text-right w-full">未完成</div>;
    }

    return (
      <div className="w-full grade-info flex flex-col">
        <div className="flex space-x-3">
          {taCredits?.map((credit) => {
            return <RedStarSVG key={JSON.stringify(credit)} className="w-5 h-5"></RedStarSVG>;
          })}
        </div>
        <div className="mt-4 flex flex-col w-full space-y-2 text-xs">
          {teacherCredits?.map((c, iTc) => {
            return (
              <div className="flex" key={JSON.stringify(c)}>
                <div className="line-clamp-1">{c.rubric.name}</div>
                <div className="flex ml-3">
                  {Array(gradeToStar(c.rubricLevel?.grade ?? 0))
                    .fill(0)
                    .map((_, iStar) => {
                      return (
                        <div key={`${iTc}-${iStar}`} className="w-[14px] h-[14px]">
                          <img src={YellowStarPNG} alt="" />
                        </div>
                      );
                    })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  if (minimal) {
    return (
      <div className="pl-6 pr-7 w-full h-full relative">
        <div className="pt-1 border-b border-[#F0403C] text-[#F0403C] flex space-x-4">
          {taCredits?.map((credit) => {
            return (
              <div
                className="flex flex-col justify-center items-center py-2"
                key={JSON.stringify(credit)}
              >
                <RedStarSVG className="w-5 h-5"></RedStarSVG>
                <div className="text-xs leading-4">{credit.rubricLevel?.text}</div>
              </div>
            );
          })}
        </div>
        <div className="text-black py-2 border-[#F0403C] border-b text-[3.9vh] leading-8 tracking-[4px]">
          {totalGrade}
        </div>
        <div className="mt-2">
          <div className="text-xs leading-[185.714%] tracking-[0.136vh]">
            {teacherCredits?.map((c) => {
              return (
                <React.Fragment key={JSON.stringify(c)}>
                  <span className="text-[#797979]">{c.rubricLevel?.text}</span>
                  <br />
                </React.Fragment>
              );
            })}
            <span className="text-[#F0403C]">{data.submission.extraComment}</span>
          </div>
        </div>
        <div className="absolute left-1/2 bottom-1 -translate-x-1/2 space-x-2 flex">
          {[1, 2, 3].map((_, i) => {
            return <div key={i} className="w-1 h-1 rounded-full bg-[#c5c5c5]"></div>;
          })}
        </div>
      </div>
    );
  }

  return (
    <div ref={refContainer} className="h-screen w-[75vh] bg-white font-medium">
      {/* Header */}
      <div className="w-full relative">
        <img src={TranscriptHeaderImage} alt="" />
        <div className="absolute top-0 bottom-0 w-full flex items-center pl-[3.4vh]">
          <img className="w-[8.79vh] h-[8.79vh]" src={MuYiTongXueLogoImage} alt="" />
          <div className="text-[1.36vh] ml-[2.12vh] text-[#f8f8f8]">
            {data.submission.authors[0]?.userIdentity[0].class.name}
          </div>
        </div>
      </div>

      <div className="ml-[7.81vh] relative">
        {/* Name */}
        <div className="text-[2.34vh] pt-[3.9vh]">{data.submission.authors[0]?.name}</div>
        <div className="absolute right-[12.7vh] top-[1.56vh] text-[1.75vh] text-[#C4C4C4]">
          学号：{data.submission.authors[0]?.userIdentity[0].studentNumber}
        </div>
        <div className="mt-[1.27vh]">
          <span className="text-[#c4c4c4] text-[1.17vh] leading-[1.95vh] inline-block">课程</span>
          <span className="text-[#797979] text-[1.76vh] leading-[4.88vh] tracking-[0.175vh] inline-block ml-[0.87vh] font-bold">
            {data.submission.assignment.lesson?.course.name.replace('_', ' ')}
          </span>
        </div>

        {/* Course */}
        <div className="flex">
          <div
            // grid-cols-1 grid-cols-2 grid-cols-3 grid-rows-1 grid-rows-2 grid-rows-3
            className={`bg-[#ffffff] w-[44.8vh] h-[38.09vh] flex-shrink-0 grid gap-2 grid-cols-${artworkMediaShapes[1]} grid-rows-${artworkMediaShapes[0]}`}
          >
            {artworkMediaUrls.map((url) => {
              return (
                <div key={url} className="flex-1">
                  <img className="object-contain" src={url} alt="" />
                </div>
              );
            })}
          </div>
          <div className=" ml-[1.56vh]">
            <div className="text-[#797979] text-[1.46vh] leading-[1.95vh] font-bold tracking-[0.146vh]">
              {data.submission.assignment.lesson?.name}
            </div>
            <div className="mt-[1.07vh]">
              <div className="text-[#D8D4CF] leading-[200%] text-[1.17vh] tracking-[0.117vh]">
                {data.submission.assignment.name.split('：')?.[0] ??
                  data.submission.assignment.name}
              </div>
              <div className="text-[#797979] text-[1.46vh] leading-[133.33%] tracking-[0.146vh]">
                {data.submission.assignment.name.split('：')?.[1]?.replace(/《(.*)》/, '$1')}
              </div>
            </div>
            <div className="w-[9.375vh] ml-[0.97vh]">
              <div className="mt-[4.39vh] border-t border-b border-[#F0403C] text-[#F0403C] flex space-x-[1.56vh]">
                {taCredits?.map((credit) => {
                  return (
                    <div
                      className="flex flex-col justify-center items-center"
                      key={JSON.stringify(credit)}
                    >
                      <RedStarSVG className="mt-[1.17vh] w-[1.95vh] h-[1.95vh]"></RedStarSVG>
                      <div className="text-[0.97vh] leading-[240%]">{credit.rubricLevel?.text}</div>
                    </div>
                  );
                })}
              </div>
              {/* <div className="border-[#F0403C] text-black border-b text-[3.9vh] leading-[125%] tracking-[0.39vh]">
                {Math.ceil(totalGrade)}
              </div> */}
            </div>
          </div>
        </div>

        {/* comment */}
        <div className="mt-[1.75vh] w-[58.59vh]">
          <div className="text-[1.17vh] leading-[166.67%] text-[#C4C4C4]">评 语</div>
          <div className="mt-[2.15vh] text-[#797979] text-[1.37vh] leading-[185.714%] tracking-[0.136vh] pb-[1.17vh] border-b border-[#c4c4c4]">
            {teacherCredits?.map((c, iTc) => {
              return (
                <React.Fragment key={JSON.stringify(c)}>
                  <div className="inline-flex pr-3 space-x-1">
                    {Array(5)
                      .fill(0)
                      .map((_, iStar) => {
                        return (
                          <div
                            key={`${iTc}-${iStar}`}
                            className={cx(
                              'w-[14px] h-[14px] opacity-100',
                              iStar >= gradeToStar(c.rubricLevel?.grade ?? 0) && '!opacity-0',
                            )}
                          >
                            <img src={YellowStarPNG} alt="" />
                          </div>
                        );
                      })}
                  </div>
                  <span>{c.rubric.name}</span>：
                  <span className="text-black">{c.rubricLevel?.text}</span>
                  <br />
                </React.Fragment>
              );
            })}
            {data.submission.extraComment && (
              <React.Fragment>
                <span className="text-[#F0403C]">特别加分：</span>
                <span className="text-black">{data.submission.extraComment}</span>
              </React.Fragment>
            )}
          </div>
        </div>

        {/* Signiture */}
        <div className="mt-[1.75vh] w-[58.59vh]">
          <div className="text-[1.17vh] leading-[166.67%] text-[#C4C4C4]">评定</div>
        </div>
      </div>
      <div className="absolute bottom-0 right-0" onClick={saveAsImage}>
        Download
      </div>
    </div>
  );
};
